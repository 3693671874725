<template>
  <div class="document-page view-page">
    <c-search searchTitle="文件名称" placeholder="请输入关键字" @search="search"></c-search>
    <c-navigation title="部门" :list="status" :isBorder="true" @change="changeDepartment"></c-navigation>
    <c-operate>
      <template #right>
        <p class="btn-list btn-add" @click="openAdd">新增</p>
      </template>
    </c-operate>
    <c-table :data="tableData">
      <!-- <el-table-column align="center" prop="date" label="选择">
        <template #default="{ row }">
          <el-checkbox></el-checkbox>
          {{ row.data }}
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="connect_username" label="交接人"> </el-table-column>
      <el-table-column align="center" prop="connect_mobile" label="手机号"> </el-table-column>
      <el-table-column align="center" prop="take_username" label="接收人"> </el-table-column>
      <el-table-column align="center" prop="take_mobile" label="手机号"> </el-table-column>
      <el-table-column align="center" prop="section" label="部门"> </el-table-column>
      <el-table-column align="center" prop="content" label="移交材料"> </el-table-column>
      <el-table-column align="center" prop="status" label="是否完成">
        <template #default="{ row }">
          <p>{{ row.status == 0 ? '未完成' : '已完成' }}</p>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="startime" label="移交时间">
        <!-- <template #default="{ row }">
          <p>{{ moment.unix(row.createtime).format('YYYY-DD-MM HH:mm:ss') }}</p>
        </template> -->
      </el-table-column>
      <el-table-column align="center" label="操作" width="120">
        <template #default="{ row }">
          <div class="table-operate" v-if="row.status == 0">
            <p class="operate-list edit" @click="edit(row)">编辑</p>
          </div>
        </template>
      </el-table-column>
    </c-table>
    <!-- 新增文件交接 -->
    <c-dialog v-model="openAddOrEdit" :title="addOrEdit" @confirm="addConfirm">
      <div class="newAdd">
        <el-form :inline="true" :model="form" label-width="80px" size="mini" class="demo-form-inline">
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择部门：">
                <el-select v-model="form.connect_section_id" placeholder="请选择" @change="changeAddDepartment">
                  <el-option v-for="item in $store.state.departmentList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="交接人：">
                <el-select v-model="form.connect_admin_id" @change="changeConnectAdminId" placeholder="请选择" :disabled="form.connect_section_id == ''">
                  <el-option v-for="item in jjrOptions" :key="item.id" :label="item.username" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式：">
                <el-input v-model="form.connect_mobile" disabled placeholder="审批人"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="接收人：">
                <el-select v-model="form.take_admin_id" @change="changeTakeAdminId" placeholder="请选择" :disabled="form.connect_section_id == ''">
                  <el-option v-for="item in jsrOptions" :key="item.id" :label="item.username" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式：">
                <el-input v-model="form.take_mobile" disabled placeholder="审批人"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="移交材料：">
                <el-input type="textarea" :rows="3" resize="none" placeholder="请输入内容" v-model="form.content"> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="移交时间：">
                <c-date-picker @select="selectedDate"></c-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择状态：">
                <el-select v-model="form.status" placeholder="请选择">
                  <el-option v-for="item in status" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </c-dialog>
    <!-- 分页 -->
    <div class="page">
      <c-pagination @change="changePage" :pageSize="connectListParam.limit" :total="contactsTotal"></c-pagination>
    </div>
  </div>
</template>

<script>
import * as hrApi from '@/api/hr.js'
import * as contactsApi from '@/api/contacts.js'
import clone from '@/utils/clone.js'
import _m from 'moment'
const formBase = {
  connect_section_id: '',
  connect_admin_id: '',
  connect_username: '',
  connect_mobile: '',
  take_username: '',
  take_admin_id: '',
  take_mobile: '',
  content: '',
  status: '',
  startime: ''
}
export default {
  name: 'Document',
  data() {
    return {
      moment: '',
      openAddOrEdit: false,
      addOrEdit: '',
      contactsTotal: 0,
      // 列表信息
      tableData: [],
      connectListParam: {
        search: '',
        status: '0',
        page: 1,
        limit: 10
      },
      form: clone(formBase),
      status: [
        { id: '0', isChecked: true, name: '未完成' },
        { id: '1', isChecked: false, name: '已完成' }
      ],
      jjrOptions: [],
      jsrOptions: [],
      editData: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    /**
     * @description: 初始化函数
     */
    init() {
      this.moment = _m
      this.getConnectList()
    },
    /**
     * @description: 获取交接列表
     */
    getConnectList() {
      hrApi.getConnectList(this.connectListParam).then(res => {
        this.tableData = res.data.data
        this.contactsTotal = res.data.total
      })
    },
    /**
     * @description: 打开新增
     */
    openAdd() {
      this.openAddOrEdit = true
      this.addOrEdit = '新增文件交接'
      const d = new Date()
      this.form.startime = d.toLocaleDateString().replaceAll('/', '-') + ' 00:00:00'
      this.form = clone(formBase)
    },
    /**
     * @description: 切换部门
     * @param {*} v: 部门参数
     */
    changeDepartment(v) {
      this.connectListParam.status = v.id
      this.getConnectList()
    },
    /**
     * @description: 切换添加选项的部门列表，来获取部门下的通讯录
     * @param {*} v: 部门参数
     */
    changeAddDepartment(v) {
      // 修改部门后重置已下几个值
      this.form.connect_section_id = v
      this.form.connect_username = ''
      this.form.connect_admin_id = ''
      this.form.connect_mobile = ''
      this.form.take_username = ''
      this.form.take_admin_id = ''
      this.form.take_mobile = ''
      this.getHandover(v)
    },
    /**
     * @description: 根据部门ID获取交接人与接受人
     * @param {*} id: 部门的id
     */
    getHandover(id) {
      const param = {
        section_id: id,
        status: 'zaizhi',
        page: 1,
        limit: 999
      }
      contactsApi.getContacts(param).then(res => {
        this.jjrOptions = res.data.data
        this.jsrOptions = res.data.data
      })
    },
    /**
     * @description: 切换交接人
     * @param {*} v: 切换后得数据
     */
    changeConnectAdminId(v) {
      for (let index = 0; index < this.jjrOptions.length; index++) {
        const i = this.jjrOptions[index]
        if (i.id == v) {
          this.form.connect_username = i.username
          this.form.connect_mobile = i.mobile
          return
        }
      }
    },
    /**
     * @description: 切换接收人
     * @param {*} v: 切换后得数据
     */
    changeTakeAdminId(v) {
      for (let index = 0; index < this.jsrOptions.length; index++) {
        const i = this.jsrOptions[index]
        if (i.id == v) {
          this.form.take_username = i.username
          this.form.take_mobile = i.mobile
          return
        }
      }
    },
    /**
     * @description: 添加的确定
     */
    addConfirm() {
      if (this.addOrEdit == '新增文件交接') {
        hrApi.addConnectData(this.form).then(res => {
          this._showMsg('添加成功', 'success')
          this.getConnectList()
          this.openAddOrEdit = false
        })
      } else if (this.addOrEdit == '编辑文件交接') {
        hrApi.editConnectData(this.form).then(res => {
          this._showMsg('编辑成功', 'success')
          this.getConnectList()
          this.openAddOrEdit = false
        })
      }
    },
    /**
     * @description: 选择时间
     * @param {*} v: 选择的时间
     */
    selectedDate(v) {
      this.form.startime = v
    },
    /**
     * @description: 编辑
     * @param {*} v: 要编辑的数据
     */
    edit(v) {
      hrApi.getConnectInfo({ id: v.id }).then(res => {
        for (const item in this.form) {
          this.form[item] = res.data[item]
        }
        this.form.id = res.data.id
        this.getHandover(this.form.connect_section_id)
        this.openAddOrEdit = true
        this.addOrEdit = '编辑文件交接'
      })
    },
    /**
     * @description: 搜索
     * @param {*} v: 参数
     */
    search(v) {
      this.connectListParam.search = v.keyWord
      this.getConnectList()
    },
    /**
     * 跳转页数
     */
    changePage(i) {
      this.connectListParam.page = i
      this.getConnectList()
    }
  }
}
</script>
<style>
.el-form-item {
  display: flex !important;
}
.el-form-item__label {
  font-size: 14px;
  padding: 0;
  flex-shrink: 0;
}
.el-form-item__content {
  flex-grow: 1;
}
</style>

<style lang="scss" scoped>
.document-page {
  background-color: #fff;
}
.btn-add {
  background: linear-gradient(109deg, #fc9940, #f37902);
}
.newAdd {
  width: 37.43rem;
}
</style>
